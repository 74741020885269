<template>
  <div id="content">
    <div id="siteNotice" />
    <h1 
      id="firstHeading" 
      class="firstHeading"
    >
      {{ name }}
    </h1>
    <div id="bodyContent">
      <p v-html="address" />
      <p 
        v-if="showContactButton" 
        class="mt-3"
      >
        <a 
          :href="url" 
          class="btn btn-primary btn-sm"
        >
          {{ dictionary.dealerContactButton }}
        </a>
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      name: { type: String, required: true },
      address: { type: String, required: true },
      url: { type: String, required: false, default: '' },
      dictionary: { type: Object, required: true },
      showContactButton: { type: Boolean, required: false, default: true },
    },
  };
</script>
  