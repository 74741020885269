var render = function render(_c, _vm) {
  return _c(
    "article",
    {
      class: [
        _vm.props.type === "regular"
          ? "article-card"
          : "article-card-highlighted",
      ],
    },
    [
      _c(
        "a",
        { staticClass: "card-wrapper", attrs: { href: _vm.props.article.url } },
        [
          _vm.props.type === "highlighted"
            ? _c("div", { staticClass: "card-overlay" }, [
                _c("img", {
                  staticClass: "card-image",
                  attrs: {
                    src: _vm.props.article.image,
                    alt: "Excavator",
                    loading: "lazy",
                  },
                }),
              ])
            : _vm.props.article.image
            ? _c("img", {
                staticClass: "card-image",
                attrs: {
                  src: _vm.props.article.image,
                  alt: "Excavator",
                  loading: "lazy",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("h3", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.props.article.title)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-tags" }, [
              _c("span", { staticClass: "article-tag is-small" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.props.article.type) + "\n        "
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }