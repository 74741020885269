/**
 * Debounce function: ensures the function is only called after a delay once no calls are made in that period.
 * @param {Function} func - Function to debounce.
 * @param {number} delay - Time in milliseconds to wait before executing the function.
 * @returns {Function} - Debounced function.
 */
export function debounce(func, delay) {
    let timer;
    return function (...args) {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(context, args), delay);
    };
}