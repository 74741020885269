var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hce-video", class: _vm.classes }, [
    !_vm.isPlaying
      ? _c("div", { staticClass: "hce-video__placeholder" }, [
          _c("img", {
            staticClass: "hce-video__placeholder-image img-fluid object-fit",
            attrs: { src: _vm.placeholder, alt: "" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hce-video__details" },
            [
              !_vm.isLoading
                ? _c(
                    "button",
                    {
                      staticClass: "hce-video__play-button",
                      attrs: { disabled: !_vm.$_requiredCookieAccepted },
                      on: { click: _vm.playVideo },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "hce-video__play-button-icon",
                          attrs: { title: "Play video" },
                        },
                        [
                          _c("use", {
                            attrs: {
                              "xlink:href": "/assets/icons/ui.svg#play-arrow",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _c("hce-loader", { attrs: { size: _vm.size } }),
              _vm._v(" "),
              !_vm.$_requiredCookieAccepted || _vm.size !== "small"
                ? _c("h2", { staticClass: "hce-video__title" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.titleMessage) + "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h3", { staticClass: "hce-video__subtitle h5" }, [
                _vm._v("\n        " + _vm._s(_vm.subtitleMessage) + "\n      "),
              ]),
              _vm._v(" "),
              !_vm.$_requiredCookieAccepted
                ? _c("hce-toggle", {
                    attrs: {
                      id: _vm.toggleId,
                      "label-on": _vm.dictionary.cookieToggleOn,
                      "label-off": _vm.dictionary.cookieToggleOff,
                      size: _vm.size === "small" ? "regular" : "large",
                      value: _vm.$_requiredCookieAccepted,
                    },
                    on: { input: _vm.onGdprToggle },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("iframe", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isInitialized && _vm.isPlaying,
          expression: "isInitialized && isPlaying",
        },
      ],
      ref: "player",
      staticClass: "hce-video__player",
      attrs: {
        title: _vm.dictionary.iframeTitle,
        src: _vm.videoUrl,
        allow:
          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }