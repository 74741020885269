<template>
  <div
    :class="classes"
    class="hce-video"
  >
    <div
      v-if="!isPlaying"
      class="hce-video__placeholder"
    >
      <img
        :src="placeholder"
        class="hce-video__placeholder-image img-fluid object-fit"
        alt=""
      >
      <div class="hce-video__details">
        <button
          v-if="!isLoading"
          class="hce-video__play-button"
          :disabled="!$_requiredCookieAccepted"
          @click="playVideo"
        >
          <svg
            class="hce-video__play-button-icon"
            title="Play video"
          >
            <use xlink:href="/assets/icons/ui.svg#play-arrow" />
          </svg>
        </button>
        <hce-loader
          v-else
          :size="size"
        />
        <h2
          v-if="!$_requiredCookieAccepted || size !== 'small'"
          class="hce-video__title"
        >
          {{ titleMessage }}
        </h2>
        <h3 class="hce-video__subtitle h5">
          {{ subtitleMessage }}
        </h3>
        <hce-toggle
          v-if="!$_requiredCookieAccepted"
          :id="toggleId"
          :label-on="dictionary.cookieToggleOn"
          :label-off="dictionary.cookieToggleOff"
          :size="size === 'small' ? 'regular' : 'large'"
          :value="$_requiredCookieAccepted"
          @input="onGdprToggle"
        />
      </div>
    </div>
    <iframe
      v-show="isInitialized && isPlaying"
      ref="player"
      :title="dictionary.iframeTitle"
      class="hce-video__player"
      :src="videoUrl"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
    />
  </div>
</template>

<script>
import { requiredCookie } from 'foundation-theming';
import HceToggle from './HceToggle.vue';
import HceLoader from './HceLoader.vue';
import { onApiReady, tryToLoadScript } from '../js/youtube-helpers';

export default {
  components: { HceToggle, HceLoader },
  mixins: [requiredCookie],
  props: {
    id: {
      type: String,
      required: true,
    },
    toggleId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator: v => ['small', 'regular'].includes(v),
      default: 'regular',
    },
    clipped: {
      type: String,
      validator: v => ['left', 'right'].includes(v),
      default: 'left',
    },
    dictionary: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      videoUrl: null,
      isPlaying: false,
      isLoading: false,
      player: null,
      isInitialized: false,
    };
  },
  computed: {
    classes() {
      return [
        {
          'hce-video--small': this.size === 'small',
          'hce-video--disabled': !this.$_requiredCookieAccepted,
        }
      ];
    },
    titleMessage() {
      return this.$_requiredCookieAccepted
        ? this.dictionary.title
        : this.dictionary.cookieMessageTitle;
    },
    subtitleMessage() {
      return this.$_requiredCookieAccepted
        ? this.dictionary.subtitle
        : this.dictionary.cookieMessageSubtitle;
    },
  },
  watch: {
    isPlaying(newValue) {
      if (newValue) {
        this.player.playVideo();
      } else {
        this.player.pauseVideo();
      }
    },
  },
  mounted() {
    onApiReady.then(() => {
      if (this.isInitialized) {
        this.setPlayer();
      }
    });
  },
  methods: {
    playVideo() {
      if (!this.isInitialized) {
        this.init();
      } else {
        this.isPlaying = true;
      }
    },

    setPlayer() {
      this.isLoading = true;

      this.player = new window.YT.Player(this.$refs.player, {
        events: {
          onReady: () => {
            this.isLoading = false;
            this.isPlaying = true;
          },
          onStateChange: this.onPlayerStateChange,
        },
      });
    },

    init() {
      this.isInitialized = true;
      this.videoUrl = `https://www.youtube.com/embed/${this.id}?enablejsapi=1&rel=0&modestbranding=1&autoplay=1`;

      if (!tryToLoadScript()) {
        this.setPlayer();
      }
    },

    onPlayerStateChange(event) {
      if (event.data === window.YT.PlayerState.ENDED) {
        this.player.seekTo(0);
        this.isPlaying = false;
      }
    },

    onGdprToggle(value) {
      if (value) {
        this.$_acceptCookie();
      }
    },
  },
};
</script>
