/**
 * Throttle function: ensures the function is called at most once in the given time interval.
 * @param {Function} func - Function to throttle.
 * @param {number} limit - Time in milliseconds to wait before allowing the next execution.
 * @returns {Function} - Throttled function.
 */
export function throttle(func, limit) {
    let lastFunc;
    let lastRan;
  
    return function (...args) {
        const context = this;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(() => {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}