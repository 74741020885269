var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "content" } }, [
    _c("div", { attrs: { id: "siteNotice" } }),
    _vm._v(" "),
    _c("h1", { staticClass: "firstHeading", attrs: { id: "firstHeading" } }, [
      _vm._v("\n    " + _vm._s(_vm.name) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "bodyContent" } }, [
      _c("p", { domProps: { innerHTML: _vm._s(_vm.address) } }),
      _vm._v(" "),
      _vm.showContactButton
        ? _c("p", { staticClass: "mt-3" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary btn-sm",
                attrs: { href: _vm.url },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.dictionary.dealerContactButton) +
                    "\n      "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }