<template>
  <div class="hce-dealer-list">
    <div class="hce-dealer-list__dealer-count">
      <span
        class="label"
        for="dealer-count"
      >
        {{ statusLabel }}
      </span>
    </div>
    <ul>
      <li
        v-for="(dealer, index) in dealers"
        ref="dealers"
        :key="dealer.guid"
        class="hce-dealer-list__dealer"
        data-dm="component.dealerlocator.dealer"
      >
        <div class="hce-dealer-list__dealer-wrapper">
          <button
            class="collapse-button"
            :aria-expanded="(selectedDealer.guid === dealer.guid).toString()"
            @click="dealerCollapsibleHandle(index, selectedDealer.guid !== dealer.guid)"
          />
          <div class="hce-dealer-list__dealer-main">
            <h3 class="h6 hce-dealer-list__dealer-heading">
              {{ dealer.name }}
            </h3>
            <div
              class="rte"
              data-dm="component.richtext"
            >
              <p v-html="dealer.location.address" />
            </div>
            <div 
              v-if="dealer.serviceTypes && dealer.serviceTypes.length > 0" 
              class="hce-dealer-list__dealer-service-types"
            >
              <span 
                v-for="serviceType in dealer.serviceTypes" 
                :key="serviceType"
                class="badge badge-primary" 
              >
                {{ serviceType }}
              </span>
            </div>
          </div>
          <div class="hce-dealer-list__dealer-toggle">
            <span class="collapse-icon" />
          </div>
          <a class="hce-dealer-list__full-link" :href="dealer.url"></a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    dealers: { type: Array, required: true, default: () => [] },
    dictionary: { type: Object, required: true, default: () => [] },
    selectedDealer: { type: Object, required: true, default: () => {} },
    statusLabel: { type: String, required: true, default: '' },
  },

  watch: {
    /**
     * If the selected dealer changes, find the element, scroll
     * to the associated card and open it
     * @param {object} dealer new one that changed
     * @returns {undefined}
     */
    selectedDealer({ guid }) {
      const dealerIndex = this.dealers.findIndex(dealer => dealer.guid === guid);

      if (dealerIndex !== -1) {
        const ref = this.$refs.dealers[dealerIndex];

        setTimeout(() => {
          // scroll to dealer inside parent container
          const parentContainer = this.$el.parentElement;
          parentContainer.scrollTop = ref.offsetTop - parentContainer.offsetTop;
        }, 200);
      }
    },
  },

  methods: {

    /**
       * Sets if a dealer is collapsed or uncollapsed (for mobile view)
       * @param {number} i Index value from dealers (original one)
       * @param {boolean} value Value to set
       * @returns {undefined}
       */
    dealerCollapsibleHandle(i, value) {
      const selection = value ? this.dealers[i] : {};
      this.$emit('select', selection);
    },
  },
};
</script>
