var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hce-dealer-locator",
      class: { detail: _vm.type === "detail" },
      attrs: { "data-dm": "component.dealerlocator" },
    },
    [
      _vm.type !== "detail"
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "introduction is-startscreen" }, [
              _c(
                "div",
                {
                  staticClass: "rte",
                  attrs: { "data-dm": "component.richtext" },
                },
                [
                  _vm._t("introduction"),
                  _vm._v(" "),
                  _c("p", { staticClass: "introduction__text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.dictionary.introductionText) +
                        "\n        "
                    ),
                  ]),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.type === "overview"
              ? _c("form", [
                  _c("div", { staticClass: "start-filter" }, [
                    _c("div", { staticClass: "country-background" }, [
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn dropdown-toggle",
                            attrs: {
                              id: "dropdownMenuButton",
                              type: "button",
                              "data-toggle": "dropdown",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("selected")(
                                    _vm.dictionary.countrySelectLabel
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "dropdownMenuButton" },
                          },
                          _vm._l(_vm.countriesWithDealer, function (c) {
                            return _c(
                              "a",
                              {
                                key: c.name,
                                staticClass: "dropdown-item",
                                attrs: { href: c.url },
                              },
                              [_vm._v(_vm._s(c.name))]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "country"
              ? _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-link btn-lg btn-link--alt btn-icon p-0 justify-content-start",
                    attrs: {
                      href: _vm.dictionary.countrySelectionLink,
                      title: _vm.dictionary.backToCountrySelection,
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon icon--arrow-left icon--left" },
                      [
                        _c("svg", { attrs: { title: "icon: arrow-left" } }, [
                          _c("use", {
                            attrs: {
                              "xlink:href": "/assets/icons/ui.svg#arrow-left",
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "btn__label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.dictionary.backToCountrySelection)
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "country"
              ? _c(
                  "div",
                  { staticClass: "dealers" },
                  [
                    _c("hce-dealer-list", {
                      attrs: {
                        dealers: _vm.filteredDealers,
                        dictionary: _vm.dictionary,
                        "selected-dealer": _vm.selectedDealer,
                        "status-label": _vm.dealerStatus,
                      },
                      on: { select: _vm.onDealerSelect },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type !== "detail"
        ? _c(
            "div",
            { staticClass: "col-md-6 map-section" },
            [
              _c("hce-dealer-map", {
                attrs: {
                  locations: _vm.dealerLocations,
                  dictionary: _vm.dictionary,
                  "selected-dealer": _vm.selectedDealer,
                  "selected-country": _vm.filters.country,
                  "user-country": _vm.userCountry,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "detail"
        ? _c("div", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "introduction is-startscreen" }, [
                    _c(
                      "div",
                      {
                        staticClass: "rte",
                        attrs: { "data-dm": "component.richtext" },
                      },
                      [_vm._t("introduction")],
                      2
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-4" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-link btn-lg btn-link--alt btn-icon p-0 justify-content-start mb-4",
                      attrs: {
                        href: _vm.dictionary.dealerSelectionLink,
                        title: _vm.dictionary.backToDealerSelection,
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon icon--arrow-left icon--left" },
                        [
                          _c("svg", { attrs: { title: "icon: arrow-left" } }, [
                            _c("use", {
                              attrs: {
                                "xlink:href": "/assets/icons/ui.svg#arrow-left",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "btn__label" }, [
                        _vm._v(_vm._s(_vm.dictionary.backToDealerSelection)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "dealer-locator-detail" }, [
                    _c(
                      "h2",
                      {
                        staticClass: "h6 hce-dealer-list__dealer-heading",
                        attrs: { rel: "dealer-locator" },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.selectedDealer.name))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "hce-dealer-list__dealer-service-types",
                          },
                          _vm._l(
                            _vm.selectedDealer.serviceTypes,
                            function (serviceType, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "badge badge-primary",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(serviceType) +
                                      "\n                "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "rte",
                        attrs: { "data-dm": "component.richtext" },
                      },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.selectedDealer.location.address
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-link btn-link--alt btn-sm",
                          attrs: {
                            href: `tel:${_vm.selectedDealer.contact.phoneNumberWithoutSpaces}`,
                            title: _vm.selectedDealer.contact.phoneNumber,
                          },
                        },
                        [
                          _c("span", { staticClass: "btn__label" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.selectedDealer.contact.phoneNumber) +
                                "\n                "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm.selectedDealer.contact.website
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-link btn-link--alt btn-sm",
                              attrs: {
                                href: _vm.selectedDealer.contact.website,
                                target: "_blank",
                              },
                            },
                            [
                              _vm.selectedDealer.contact.websiteText
                                ? _c("span", { staticClass: "btn__label" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.selectedDealer.contact.websiteText
                                        ) +
                                        "\n                "
                                    ),
                                  ])
                                : _c("span", { staticClass: "btn__label" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.selectedDealer.contact.website
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-link btn-link--alt btn-sm",
                          attrs: {
                            href: `mailto:${_vm.selectedDealer.contact.email}`,
                            title: _vm.selectedDealer.contact.email,
                          },
                        },
                        [
                          _c("span", { staticClass: "btn__label" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.selectedDealer.contact.email) +
                                "\n                "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-4" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm.selectedDealer.location.lat &&
                        _vm.selectedDealer.location.lng &&
                        _vm.selectedDealer.location.lat !== 0.0 &&
                        _vm.selectedDealer.location.lng !== 0.0
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn-secondary btn-sm",
                                attrs: {
                                  href: _vm.createDirectionsLink(
                                    _vm.selectedDealer
                                  ),
                                  title: _vm.dictionary.dealerDirections,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                },
                              },
                              [
                                _c("span", { staticClass: "btn__label" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.dictionary.dealerDirections) +
                                      "\n                  "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-secondary btn-sm",
                            attrs: {
                              href: `mailto:${_vm.selectedDealer.contact.email}`,
                              title: _vm.dictionary.dealerContactButton,
                            },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.dictionary.dealerContactButton) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("hce-dealer-map", {
                      attrs: {
                        locations: _vm.dealerLocations,
                        dictionary: _vm.dictionary,
                        "selected-dealer": _vm.selectedDealer,
                        "selected-country": _vm.filters.country,
                        "user-country": _vm.userCountry,
                        type: _vm.type,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "div",
                    { staticClass: "dealer-introduction d-flex gap-4 my-10" },
                    [
                      _vm.selectedDealer.introduction.length
                        ? _c("div", { staticClass: "rte w-100" }, [
                            _vm.selectedDealer.dealerIntroductionHeader.length
                              ? _c("h2", { staticClass: "h6" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.selectedDealer
                                          .dealerIntroductionHeader
                                      ) +
                                      "\n              "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.selectedDealer.introduction
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedDealer.logoUrl.length
                        ? _c("div", { staticClass: "border p-5" }, [
                            _c("img", {
                              staticClass: "img-fluid",
                              attrs: {
                                src: _vm.selectedDealer.logoUrl,
                                alt: _vm.selectedDealer.name,
                                width: "200",
                                height: "auto",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dealer-details d-flex gap-4 my-10" },
                    [
                      _vm.decodedOpeningHours.length
                        ? _c("div", {
                            staticClass: "opening-hours w-50",
                            domProps: {
                              innerHTML: _vm._s(_vm.decodedOpeningHours),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedDealer.supportedProducts.length
                        ? _c(
                            "div",
                            { staticClass: "dealer-supported-products w-50" },
                            [
                              _vm.selectedDealer.machinesHeader
                                ? _c("h2", { staticClass: "h6" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.selectedDealer.machinesHeader
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap gap-2" },
                                _vm._l(
                                  _vm.selectedDealer.supportedProducts,
                                  function (product, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: index,
                                        staticClass:
                                          "badge badge-white font-weight-normal border p-2",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(product) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.dealerPromotions.length
                    ? _c("div", { staticClass: "dealer-promo my-10" }, [
                        _vm.selectedDealer.offersHeader
                          ? _c("h2", { staticClass: "h6" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.selectedDealer.offersHeader) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-deck" },
                          _vm._l(
                            _vm.dealerPromotions,
                            function (promotion, index) {
                              return _c(
                                promotion.link.length ? "a" : "div",
                                _vm._b(
                                  {
                                    key: index,
                                    tag: "component",
                                    staticClass: "card",
                                    attrs: { "data-dm": "component.card" },
                                  },
                                  "component",
                                  promotion.link.length
                                    ? { href: promotion.link }
                                    : {},
                                  false
                                ),
                                [
                                  promotion.image.length
                                    ? _c("img", {
                                        staticClass:
                                          "card-img-top img-fluid object-fit clipped--small clipped--left",
                                        attrs: {
                                          src: promotion.image,
                                          alt:
                                            promotion.imageAlt ||
                                            promotion.title,
                                          loading: "lazy",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "card-body" }, [
                                    promotion.title.length
                                      ? _c(
                                          "h3",
                                          { staticClass: "h6 card-title" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(promotion.title) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "card__text" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(promotion.summary) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    promotion.linkText.length
                                      ? _c("p", { staticClass: "card__link" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(promotion.linkText) +
                                              "\n                  "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }