<template>
  <div class="hce-dealer-map" />
</template>

<script>
import Vue from 'vue';
import { Loader } from 'google-maps';
import HceDealerInfoWindow from './HceDealerInfoWindow.vue';

const MAP_MARKER = '/assets/images/map/marker.svg';
const MAP_MARKER_ACTIVE = '/assets/images/map/marker-active.svg';
const ZOOM_LEVEL_INITIAL = 4;
const ZOOM_LEVEL_DEALER = 10;
const ZOOM_LEVEL_COUNTRY = 6;

const options = {
  version: 'quarterly', // or 'weekly' if you prefer
};

const loader = new Loader('AIzaSyChfqIRjRy1LbA5J4EUsKuCpNpAXovJsQk', options);

export default {
  props: {
    dictionary: { type: Object, required: true, default: () => {} },
    isDisabled: { type: Boolean, required: true, default: false },
    locations: { type: Array, required: true, default: () => [] },
    selectedDealer: { type: Object, required: true, default: () => {} },
    selectedCountry: { type: String, required: true, default: '' },
    userCountry: { type: String, required: true, default: '' },
    type: { type: String, required: false, default: '' },
  },
  data() {
    return {
      map: null,
      markers: [],
    };
  },
  watch: {
    locations(newLocations) {
      this.loadMarkers(newLocations);
    },
    selectedDealer(dealer) {
      this.highlightMarker(dealer);
    },
    selectedCountry(country) {
      this.centerCountry(country);
    },
    userCountry(country) {
      this.centerCountry(country);
    },
  },
  mounted() {
    loader.load().then((google) => {
      this.map = new google.maps.Map(this.$el, {
        disableDefaultUI: true,
        zoomControl: true,
      });
      this.geocoder = new google.maps.Geocoder();
      this.openedInfoWindow = null;
      this.selectedMarker = null;

      this.centerCountry(this.selectedCountry);
      this.centerCountry(this.userCountry, true);
      this.loadMarkers(this.locations);
      this.highlightMarker(this.selectedDealer);
    });
  },

  methods: {
    /**
     * Find the marker with correct dealer guid and change the icon
     * @param {object} dealer to highlight associated marker
     * @returns {undefined}
     */
    highlightMarker(dealer) {
      this.markers.forEach((marker) => {
        if (marker.guid === dealer.guid) {
          this.clickMarker(marker);
        }
      });
    },

    /**
     * Reset and paint markers based on location coordinates given
     * @param {array} locations to add associated markers
     * @returns {undefined}
     */
    loadMarkers(locations) {
      console.dir(this.type);

      // Removes the markers from the map
      this.markers.forEach(marker => marker.setMap(null));
      // Reset the markers
      this.currentWindow = null;
      locations.forEach(({ location, guid, name, url }) => {
        // only add marker if lat long is not empty
        if (location.lat && location.lng) {
          const marker = new google.maps.Marker({
            position: location,
            map: this.map,
            icon: this.getIconImage(MAP_MARKER),
            optimized: false,
            guid,
          });

          const infoWindow = new google.maps.InfoWindow({
            content: this.createInfoWindowContent(location, name, url, this.dictionary, this.type),
          });

          marker.addListener('click', () => {
            if (this.openedInfoWindow) {
              this.openedInfoWindow.close();
            }
            if (this.selectedMarker) {
              this.selectedMarker.setIcon(this.getIconImage(MAP_MARKER));
            }
            this.map.setZoom(ZOOM_LEVEL_DEALER);
            this.map.setCenter(marker.getPosition());
            infoWindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false,
            });
            marker.setIcon(this.getIconImage(MAP_MARKER_ACTIVE));
            this.selectedMarker = marker;
            this.openedInfoWindow = infoWindow;
          });
          
          this.markers.push(marker);
        }
      });
    },

    /**
     * Create and return a new InfoWindow
     * @param {Object} location - the location object containing the data for the info window
     * @returns {google.maps.InfoWindow} The created InfoWindow
     */
    createInfoWindowContent(location, name, url, dictionary, type) {
      const InfoWindowComponent = Vue.extend(HceDealerInfoWindow);
      const infoWindowComponent = new InfoWindowComponent({
        propsData: {
          name: name,
          address: location.address,
          url: url,
          dictionary: dictionary,
          showContactButton: type !== 'detail',
        },
      });

      // Mount the component to an element
      infoWindowComponent.$mount();

      // pass its element to the content of google.maps.InfoWindow
      return infoWindowComponent.$el;
    },

    /**
     * Based on country name center and zoom to the location on map
     * @param {string} country to highlight
     * @returns {undefined}
     */
    centerCountry(country, initial = false) {
      this.geocoder.geocode({ address: country }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.map.setZoom(initial ? ZOOM_LEVEL_INITIAL : ZOOM_LEVEL_COUNTRY);
          this.map.panTo(results[0].geometry.location);
        }
      });
    },

    clickMarker(marker) {
      google.maps.event.trigger(marker, 'click');
    },

    /**
     * Create an object to use as map icon with fixed size (IE11 fix)
     * @param {string} url that links to icon image
     * @returns {object} icon
     */
    getIconImage(url) {
      return {
        url,
        scaledSize: new google.maps.Size(30, 34),
      };
    }
  },
};
</script>
