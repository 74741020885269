<template>
  <div
    data-dm="component.product-specifications"
    class="hce-product-specifications"
  >
    <div class="hce-product-specifications__container">
      <div
        ref="wrapper"
        class="hce-product-specifications__wrapper"
      >
        <div
          class="hce-product-specifications__label hce-product-specifications__scroll-hint"
          :class="{ 'is-hidden': isScrolling }"
        >
          {{ dictionary.scrollHint }}
          <span class="icon icon--arrow-right">
            <svg title="icon: arrow-right">
              <use xlink:href="/assets/icons/ui.svg#arrow-right" />
            </svg>
          </span>
        </div>
        <div class="hce-product-specifications__introductory-tables">
          <hce-product-table
            v-for="category in remainingCategories"
            :key="category.name"
            :calculate-unit="getCorrectUnit"
            :category="category"
          />
        </div>
        <svg
          class="cut-corner"
          viewBox="0 0 15 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="15 0, 15 24, 0 24" />
          <line
            x1="0"
            y1="24"
            x2="15"
            y2="0"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '@valtech/utilities';
import HceProductTable from './HceProductTable.vue';

export default {
  components: {
    HceProductTable,
  },

  props: {
    dictionary: { type: Object, required: true, default: () => {} },
    specifications: { type: Object, required: true, default: () => {} },
    unitType: { type: String, required: true },
  },

  data() {
    return {
      isScrolling: false,
      isExpanded: false,
    };
  },

  computed: {
    /**
     * Returns only the first two categories
     * @returns {array} first two categories
     */
    introductoryCategories() {
      return this.productCategories.slice(0, 2);
    },

    /**
     * Returns every category after the first two
     * @returns {array} remaining categories
     */
    remainingCategories() {
      return this.productCategories;
    },

    /**
     * Transforms the specifications to separate each product category
     * @returns {array} product categories
     */
    productCategories() {
      return this.specifications.items.reduce((result, { category, ...restOfItem }) => {
        const index = result.findIndex(({ name }) => name && name === category);

        if (index !== -1) {
          result[index].items.push(restOfItem);
        } else {
          result.push({ name: category, items: [restOfItem] });
        }

        return result;
      }, []);
    },
  },

  /**
   * Add a scroll listener to the wrapper
   * @returns {undefined}
   */
  mounted() {
    this.$refs.wrapper.addEventListener('scroll', throttle(this.handleWrapperScroll, 300));
  },

  methods: {
    /**
     * Will set a variable based on if the user has scrolled in the wrapper
     * @param {object} target of wrapper on scroll event
     * @returns {undefined}
     */
    handleWrapperScroll({ target }) {
      this.isScrolling = target.scrollLeft > 0;
    },

    /**
     * Returns the unit value for an item
     * Use general if filled in, else use specific imperial or metric unit
     * @param {object} value with unit properties
     * @returns {string} unit
     */
    getCorrectUnit(values) {
      if (!values) {
        return null;
      }

      const { general, imperial, metric } = values;

      if (general) {
        return general;
      }

      return this.unitType.toLowerCase() === 'imperial' ? imperial : metric;
    },

    
  
  },
};
</script>
